<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-container>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
        <v-form v-model="valid">
          <v-row>
            <v-col sm="4">
              <v-text-field :rules="oldPasswordRules"
                            label="Old Password"
                            v-model="oldPassword"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field :rules="newPasswordRules"
                            label="New Password"
                            v-model="newPassword"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="newPasswordConfirmation"
                            :rules="newPasswordConfirmationRules"
                            label="Password Confirmation"></v-text-field>
            </v-col>
            <v-btn style="box-shadow: none!important; color: white" height="60px" width="200px" rounded
                   v-if="this.valid" color="#39bf88" @click="changePass">Update
            </v-btn>
            <v-btn style="box-shadow: none!important; color: white" height="60px" width="200px" rounded v-else disabled>
              Update
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </div>
    <div v-else>
      <v-alert
          v-model="NOTIFICATION_STATUS"
          :color="NOTIFICATION_COLOR"
          elevation="24"
          type="success"
          dismissible
      >{{ NOTIFICATION }}
      </v-alert>
      <v-form v-model="valid">
        <v-row class="mt-16">
          <v-col class="px-8" cols="12">
            <v-text-field style="border-radius: 12px" outlined :rules="oldPasswordRules"
                          color="black"
                          label="Old Password"
                          v-model="oldPassword"></v-text-field>
          </v-col>
          <v-col class="px-8" cols="12">
            <v-text-field :rules="newPasswordRules"
                          style="border-radius: 12px" outlined
                          color="black"
                          label="New Password"
                          v-model="newPassword"></v-text-field>
          </v-col>
          <v-col class="px-8" cols="12">
            <v-text-field
                style="border-radius: 12px" outlined
                color="black"
                v-model="newPasswordConfirmation"
                :rules="newPasswordConfirmationRules"
                label="Password Confirmation"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn style="box-shadow: none!important; color: white;border-radius: 12px" height="45px" width="150px"  v-if="valid"
                   color="#39bf88" @click="changePass">Update
            </v-btn>
            <v-btn style="box-shadow: none!important; color: white;border-radius: 12px" height="45px" width="150px"  v-else disabled>
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      screenType: null,
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
      valid: false,
      oldPasswordRules: [
        v => !!v || 'old password is required',
      ],
      newPasswordRules: [
        v => (!!v && v.length >= 6) || 'new password is required to be a at least 6 characters',
      ],
      newPasswordConfirmationRules: [
        v => (!!v && v == this.newPassword) || 'Password confirmation failed'
      ]
    }
  },
  mounted() {
    this.getScreenType()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    changePass() {
      window.axios.post('api/me/change-password', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }).then(() => {
        this.NOTIFICATION_STATUS = true
        this.NOTIFICATION_COLOR = 'green'
        this.NOTIFICATION = 'Password updated!'
      }).catch((err) => {
        this.NOTIFICATION_STATUS = true
        this.NOTIFICATION_COLOR = 'red'
        this.NOTIFICATION = err.response.data.errors[0]
      })
    }
  }
}
</script>

<style scoped>

</style>